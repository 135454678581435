/**
 * Contém variáveis de ambiente para mais fácil utilização
 */
export const Environment = {
  /**
   * Informa a url para as APIs do Central de conversão
   */

  URL_API: 'https://api.gvdasa.com.br/conversao/api/v1',

  URL_ORIGIN_DEBUG: window.location.host.includes('localhost')
    ? 'https://area87gvcollege1-dev.conversao.gvdasa.com.br'
    : '',
};
