/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useRef,
  useMemo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Box,
  Grid,
  Link,
  Button,
  MenuItem,
  IconButton,
  Typography,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import {Form} from '@unform/web';
import InputMask from 'react-input-mask';
import {FormHandles} from '@unform/core';
import {format, addHours} from 'date-fns';
import {ObjectShape} from 'yup/lib/object';
import ReCAPTCHA from 'react-google-recaptcha';
import {useNavigate, useParams} from 'react-router-dom';
import {CloudUpload, Create, Delete, Visibility} from '@material-ui/icons';
import {
  Header,
  Checkbox,
  TextField,
  InfoTooltip,
  MultiSelect,
  SplashScreen,
} from 'shared/components';
import {useResponse} from 'shared/hooks';
import {
  AvailabilitiesService,
  IScheduleAvailability,
} from 'shared/services/api/ScheduleAvailabilityService';
import stringCapitalize from 'shared/utils/stringCapitalize';
import getValidationErrors from 'shared/utils/getValidationErrors';
import setValidationErrors from 'shared/utils/setValidationErrors';
import {AvailableSchedule} from 'shared/components/AvailableSchedule';
import {
  FormService,
  IFormFields,
  IResponseForm,
} from 'shared/services/api/FormService';
import {AddressService, IAddress} from 'shared/services/api/AddressService';

import {useStyles} from './styles';

interface IInputVisibleProps {
  value?: string;
  visible: boolean;
}

export interface IInputVisible {
  [key: string]: IInputVisibleProps;
}

export interface ICepActive {
  [key: string]: boolean;
}

export const FormPage: React.FC = () => {
  const [recaptcha, setRecaptcha] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [address, setAddress] = useState<IAddress[]>([]);
  const [isInvalideCep, setIsInvalideCep] = useState(false);
  const [cepActive, setCepActive] = useState<ICepActive>({});
  const [inputVisible, setInputVisible] = useState<IInputVisible>({});
  const [formValidation, setFormValidation] = useState({} as ObjectShape);
  const [availabilities, setAvailabilities] = useState<IScheduleAvailability[]>(
    [],
  );
  const [predefined, setPredefined] = useState({
    establishment: '',
    course: '',
    cycle: '',
    shift: '',
    additional1: '',
    additional2: '',
  });

  const classes = useStyles();
  const navigate = useNavigate();
  const {
    crypto,
    idLead = '',
    idCampaign = '',
  } = useParams<'crypto' | 'idLead' | 'idCampaign'>();

  const {
    isInternal,
    getData,
    getInternalData,
    getInternalEditData,
    postInternalData,
    postData,
    putData,
    setCrypto,
    idEstablishment,
    idCourse,
    idCycle,
    idShift,
    form,
    offers,
    personalization,
    course,
    cycle,
    establishment,
    shift,
    loading,
    isError,
    message,
    isEditing,
    permissionEdit,
    useZipCode,
  } = useResponse();

  const formRef = useRef<FormHandles>(null);

  const handleGetAddress = useCallback(
    async (cep: string, index: number) => {
      setIsLoading(true);

      if (!cep.includes('_') && cep.length >= 8) {
        const response = await AddressService.getAddress(cep);

        const addressCopy: IAddress[] = address;
        if (response) {
          addressCopy[index] = response;
          setAddress([...addressCopy]);

          setCepActive((state) => (state = {...state, [index]: true}));
          setIsInvalideCep(false);
        } else {
          addressCopy[index] = {
            cep,
            bairro: '',
            logradouro: '',
            localidade: '',
            uf: '',
          };
          setAddress([...addressCopy]);

          setCepActive((state) => (state = {...state, [index]: false}));
          setIsInvalideCep(true);
        }
      }
      setIsLoading(false);
    },
    [address],
  );

  const handleGetFile = useCallback(async (idArquivo: string) => {
    try {
      setIsLoading(true);
      const response = await FormService.getFile(idArquivo);
      if (response.sucesso) {
        const idArquivo: HTMLAnchorElement = document.createElement('a');

        idArquivo.href = `data:application/octet-stream;base64,${response.data.conteudo}`;
        idArquivo.download = response.data.nomeOriginal;
        idArquivo.click();
      } else {
        console.error('Erro ao tentar carregar o arquivo.');
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleChangeRecaptcha = useCallback((value: string | null) => {
    if (value) setRecaptcha(value);
  }, []);

  const handleMaxColumns = useCallback((value: number) => {
    switch (value) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      default:
        return 12;
    }
  }, []);

  const handleValuePredefined = useCallback(
    (
      idCampoPredefinido: number,
      idCampo: string,
      identificadorCampo: string,
    ): string => {
      switch (idCampoPredefinido) {
        case 2:
          return predefined.establishment;
        case 3:
          return predefined.course;
        case 4:
          return predefined.cycle;
        case 5:
          return predefined.shift;
        case 6:
          return predefined.additional1;
        case 7:
          return predefined.additional2;
        default: {
          const anySelectField = stringCapitalize(identificadorCampo, idCampo);
          return formRef.current?.getFieldValue(anySelectField);
        }
      }
    },
    [
      predefined.course,
      predefined.cycle,
      predefined.establishment,
      predefined.shift,
      predefined.additional1,
      predefined.additional2,
    ],
  );

  const idOffer = useMemo(() => {
    if (predefined.shift) {
      return offers.find(
        (i) =>
          (i.idEstabelecimento === predefined.establishment ||
            i.idEstabelecimento === idEstablishment) &&
          (i.idCurso === predefined.course || i.idCurso === idCourse) &&
          i.idCiclo === predefined.cycle &&
          i.idTurno === predefined.shift,
      )?.id;
    }
    return offers.find(
      (i) =>
        (i.idEstabelecimento === predefined.establishment ||
          i.idEstabelecimento === idEstablishment) &&
        (i.idCurso === predefined.course || i.idCurso === idCourse) &&
        i.idCiclo === predefined.cycle,
    )?.id;
  }, [
    offers,
    predefined.cycle,
    predefined.course,
    predefined.establishment,
    predefined.shift,
    idEstablishment,
    idCourse,
  ]);

  const offerSelected = useMemo(() => {
    return offers.find((item) => item.id === idOffer);
  }, [idOffer, offers]);

  const timezone = useMemo(() => {
    return new Date().getHours() - new Date().getUTCHours();
  }, []);

  const handleGetSchedules = useCallback(
    async (idOffer: string) => {
      const response = await AvailabilitiesService.getAvailabilities(idOffer);

      const availabilities = response?.map(
        (availability: IScheduleAvailability) =>
          ({
            ...availability,
            iniciaEm: format(
              addHours(new Date(availability.iniciaEm), timezone),
              "yyyy-MM-dd'T'HH:mm",
            ),
            terminaEm: format(
              addHours(new Date(availability.terminaEm), timezone),
              "yyyy-MM-dd'T'HH:mm",
            ),
          } as IScheduleAvailability),
      );

      if (availabilities) {
        setAvailabilities([...availabilities]);
      }
    },
    [timezone],
  );

  const handleSetPredefined = useCallback(
    (
      target,
      idPredefinido: number,
      identificadorCampo: string,
      idCampo: string,
    ) => {
      switch (idPredefinido) {
        case 2:
          {
            const establ = stringCapitalize(identificadorCampo, idCampo);
            formRef.current?.setFieldValue(establ, target.value);
            setPredefined(
              (state) =>
                (state = {
                  ...state,
                  establishment: String(target.value),
                }),
            );
          }
          break;
        case 3:
          {
            const course = stringCapitalize(identificadorCampo, idCampo);
            formRef.current?.setFieldValue(course, target.value);
            setPredefined(
              (state) =>
                (state = {
                  ...state,
                  course: String(target.value),
                }),
            );
          }
          break;
        case 4: {
          const cycle = stringCapitalize(identificadorCampo, idCampo);
          formRef.current?.setFieldValue(cycle, target.value);
          setPredefined(
            (state) =>
              (state = {
                ...state,
                cycle: String(target.value),
              }),
          );
          break;
        }
        case 5:
          {
            const shift = stringCapitalize(identificadorCampo, idCampo);
            formRef.current?.setFieldValue(shift, target.value);
            setPredefined(
              (state) =>
                (state = {
                  ...state,
                  shift: String(target.value),
                }),
            );
          }
          break;
        case 6: {
          const additional1 = stringCapitalize(identificadorCampo, idCampo);
          formRef.current?.setFieldValue(additional1, target.value);
          setPredefined(
            (state) =>
              (state = {
                ...state,
                additional1: String(target.value),
              }),
          );
          break;
        }
        case 7: {
          const additional2 = stringCapitalize(identificadorCampo, idCampo);
          formRef.current?.setFieldValue(additional2, target.value);
          setPredefined(
            (state) =>
              (state = {
                ...state,
                additional2: String(target.value),
              }),
          );
          break;
        }
        default: {
          const defaultSelectValue = stringCapitalize(
            identificadorCampo,
            idCampo,
          );
          formRef.current?.setFieldValue(defaultSelectValue, target.value);
          break;
        }
      }
    },
    [],
  );

  const handleSetAddress = useCallback(
    (id: number, index: number) => {
      if (cepActive) {
        switch (id) {
          case 9:
            return address[index]?.logradouro || '';
          case 12:
            return address[index]?.uf || '';
          case 13:
            return address[index]?.localidade || '';
          case 14:
            return address[index]?.bairro || '';
          default:
            return undefined;
        }
      }
      return undefined;
    },
    [cepActive, address],
  );

  const initialData = useMemo(() => {
    let aux = {};
    if (form) {
      form.map((item) => {
        item?.campos?.map((field) => {
          if (field.ativo) {
            if (field.idCampoPredefinido === 2 && idEstablishment) {
              aux = {
                ...aux,
                [stringCapitalize(field.nomeCampo, field.idCampo)]:
                  idEstablishment,
              };
            } else if (field.idCampoPredefinido === 3 && idCourse) {
              aux = {
                ...aux,
                [stringCapitalize(field.nomeCampo, field.idCampo)]: idCourse,
              };
            } else if (field.resposta) {
              if (
                field.idCampoPredefinido === 4 &&
                field?.resposta?.opcoes[0]?.valor
              ) {
                setPredefined(
                  (state) =>
                    (state = {
                      ...state,
                      cycle: String(field.resposta?.opcoes[0]?.valor),
                    }),
                );

                aux = {
                  ...aux,
                  [stringCapitalize(field.nomeCampo, field.idCampo)]:
                    field.resposta.opcoes[0].valor,
                };
              } else if (
                field.idCampoPredefinido === 5 &&
                field?.resposta?.opcoes[0]?.valor
              ) {
                setPredefined(
                  (state) =>
                    (state = {
                      ...state,
                      shift: String(field.resposta?.opcoes[0]?.valor),
                    }),
                );

                aux = {
                  ...aux,
                  [stringCapitalize(field.nomeCampo, field.idCampo)]:
                    field.resposta.opcoes[0].valor,
                };
              }
              if (field.idCampo === field.resposta.idCampoDaFicha) {
                switch (field.idTipoDeCampo) {
                  case 2:
                    aux = {
                      ...aux,
                      [stringCapitalize(field.nomeCampo, field.idCampo)]:
                        field.resposta.valorChecado,
                    };
                    break;
                  case 4:
                    aux = {
                      ...aux,
                      [stringCapitalize(field.nomeCampo, field.idCampo)]: '',
                    };
                    break;
                  case 5: {
                    if (field.idCampoPredefinido === 6) {
                      const optionsLength =
                        field.resposta?.opcoes.length > 0
                          ? field.resposta?.opcoes.length - 1
                          : 0;
                      setPredefined(
                        (state) =>
                          (state = {
                            ...state,
                            additional1: String(
                              field.resposta?.opcoes[optionsLength]?.valor ||
                                '',
                            ),
                          }),
                      );
                    }
                    if (field.idCampoPredefinido === 7) {
                      const optionsLength =
                        field.resposta?.opcoes.length > 0
                          ? field.resposta?.opcoes.length - 1
                          : 0;
                      setPredefined(
                        (state) =>
                          (state = {
                            ...state,
                            additional2: String(
                              field.resposta?.opcoes[optionsLength]?.valor,
                            ),
                          }),
                      );
                    }
                    const optionsLength =
                      field.resposta?.opcoes.length > 0
                        ? field.resposta?.opcoes.length - 1
                        : 0;
                    aux = {
                      ...aux,
                      [stringCapitalize(field.nomeCampo, field.idCampo)]:
                        field.resposta.opcoes[optionsLength]?.valor || '',
                    };
                    break;
                  }
                  case 6:
                    if (field.resposta.opcoes)
                      aux = {
                        ...aux,
                        [stringCapitalize(field.nomeCampo, field.idCampo)]:
                          field.resposta.opcoes.map((i) => i.valor),
                      };
                    break;
                  default:
                    aux = {
                      ...aux,
                      [stringCapitalize(field.nomeCampo, field.idCampo)]:
                        field.resposta.valor,
                    };
                    break;
                }
              }
            }
          }
        });
      });
    }

    return aux;
  }, [form, idEstablishment, idCourse]);

  const courseFiltered = useMemo(
    () =>
      course.filter((i) => i.idEstabelecimento === predefined.establishment),
    [course, predefined.establishment],
  );

  const cycleFiltered = useMemo(
    () =>
      cycle.filter(
        (i) =>
          i.idCurso === predefined.course &&
          i.idEstabelecimento === predefined.establishment,
      ),
    [cycle, predefined.course, predefined.establishment],
  );

  const shiftFiltered = useMemo(
    () =>
      shift.filter(
        (i) =>
          i.idCiclo === predefined.cycle &&
          i.idCurso === predefined.course &&
          i.idEstabelecimento === predefined.establishment,
      ),
    [shift, predefined.cycle, predefined.course, predefined.establishment],
  );

  const handleOptPredefined = useCallback(
    (field: IFormFields) => {
      switch (field.idCampoPredefinido) {
        case 2:
          return establishment;
        case 3:
          return courseFiltered;
        case 4:
          return cycleFiltered;
        case 5:
          return shiftFiltered;
        case 6:
          return offerSelected?.complementos1DaOferta || [];
        case 7:
          return offerSelected?.complementos2DaOferta || [];
        default:
          return field.opcoes;
      }
    },
    [
      establishment,
      courseFiltered,
      cycleFiltered,
      shiftFiltered,
      offerSelected?.complementos1DaOferta,
      offerSelected?.complementos2DaOferta,
    ],
  );

  const handleOnSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const respostas: IResponseForm[] = [];
        let shiftName = '';
        let scheduleUsage;

        form.map((item) =>
          item.campos.map((field) => {
            if (field.idTipoDeCampo === 1) {
              scheduleUsage = availabilities.filter(
                (i) =>
                  `${format(
                    new Date(i.iniciaEm),
                    'dd/MM/yyyy HH:mm',
                  )} às ${format(new Date(i.terminaEm), 'HH:mm')}` ===
                  data[stringCapitalize(field.nomeCampo, field.idCampo)],
              )[0]?.idDisponibilidadeDeAgenda;

              if (!scheduleUsage) {
                setSubmitted(true);
                return;
              }
            }

            if (field.idCampoPredefinido === 5) {
              shiftName = stringCapitalize(field.nomeCampo, field.idCampo);
            }

            const fieldIdentifier = stringCapitalize(
              field.nomeCampo,
              field.idCampo,
            );
            const fieldValue = formRef.current?.getFieldValue(fieldIdentifier);

            respostas.push({
              idCampoDaFicha: String(field.idCampo),
              valor:
                field.idTipoDeCampo === 7 ||
                field.idTipoDeCampo === 8 ||
                field.idTipoDeCampo === 9 ||
                field.idTipoDeCampo === 10 ||
                field.idTipoDeCampo === 3
                  ? data[stringCapitalize(field.nomeCampo, field.idCampo)]
                  : field.idTipoDeCampo === 1
                  ? availabilities.filter(
                      (i) =>
                        `${format(
                          new Date(i.iniciaEm),
                          'dd/MM/yyyy HH:mm',
                        )} às ${format(new Date(i.terminaEm), 'HH:mm')}` ===
                        data[stringCapitalize(field.nomeCampo, field.idCampo)],
                    )[0].idDisponibilidadeDeAgenda
                  : field.idTipoDeCampo === 4 &&
                    !inputVisible[field.idCampo].visible
                  ? inputVisible[field.idCampo].value
                  : '',
              valorChecado:
                field.idTipoDeCampo === 2
                  ? data[stringCapitalize(field.nomeCampo, field.idCampo)]
                  : false,
              nomeArquivo:
                field.idTipoDeCampo === 4 &&
                !!data[stringCapitalize(field.nomeCampo, field.idCampo)]
                  ? data[stringCapitalize(field.nomeCampo, field.idCampo)].name
                  : field.idTipoDeCampo === 4 &&
                    !inputVisible[field.idCampo].visible
                  ? field.resposta?.nomeArquivo
                  : '',
              conteudoArquivo:
                field.idTipoDeCampo === 4 &&
                !!data[stringCapitalize(field.nomeCampo, field.idCampo)]
                  ? data[
                      `${stringCapitalize(field.nomeCampo, field.idCampo)}-file`
                    ]
                  : '',
              opcoes:
                field.idTipoDeCampo === 6
                  ? data[stringCapitalize(field.nomeCampo, field.idCampo)]?.map(
                      (opt: string) => {
                        return {id: opt};
                      },
                    )
                  : field.idTipoDeCampo === 5
                  ? [
                      {
                        id: fieldValue,
                      },
                    ]
                  : [],
            });
          }),
        );

        if (shiftFiltered.length === 0) {
          delete formValidation[shiftName];
        }

        const schema = Yup.object().shape(formValidation);
        await schema.validate(data, {
          abortEarly: false,
        });

        if (!recaptcha && !isInternal) {
          return;
        }
        setSubmitted(false);
        if (idOffer) {
          if (isInternal) {
            if (isEditing) {
              await putData(idLead, {
                idOferta: idOffer,
                respostas,
              });
            } else {
              await postInternalData({
                idOferta: idOffer,
                respostas,
              });
            }
          } else {
            await postData(recaptcha, {
              idOferta: idOffer,
              respostas,
            });
          }
        }

        navigate('/confirmacao');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err as Yup.ValidationError);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [
      form,
      shiftFiltered.length,
      formValidation,
      recaptcha,
      isInternal,
      idOffer,
      navigate,
      availabilities,
      inputVisible,
      isEditing,
      putData,
      idLead,
      postInternalData,
      postData,
    ],
  );

  const handleFormatText = useCallback(
    (textValue: string, formatType: string) => {
      switch (formatType) {
        case 'uppercase':
          return textValue.toUpperCase();
        case 'lowercase':
          return textValue.toLowerCase();
        case 'titlecase':
          return textValue.replace(/\w\S*/g, (txt: string) => {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
          });
        case 'sentensecase':
          return textValue.replace(/(^\w{1}|\.\s*\w{1})/gi, (txt: string) => {
            return txt.toUpperCase();
          });

        default:
          return textValue;
      }
    },
    [],
  );

  const handleChange = useCallback(() => {
    form.map((item) => {
      item.campos
        .filter((field) => field.idTipoDeCampo === 4)
        .map((field) => {
          const nomeCampo = stringCapitalize(field.nomeCampo, field.idCampo);
          const value = formRef.current?.getFieldValue(nomeCampo);

          if (value) {
            const reader = new FileReader();

            reader.onload = () => {
              const {result} = reader;

              formRef.current?.setFieldValue(
                `${nomeCampo}-file`,
                String(result).split(',')[1],
              );
            };

            reader.readAsDataURL(value);
          }
        });
      item.campos
        .filter(
          (field) => field.idTipoDeCampo === 9 || field.idTipoDeCampo === 10,
        )
        .map((field) => {
          const nomeCampo = stringCapitalize(field.nomeCampo, field.idCampo);
          const value = formRef.current?.getFieldValue(nomeCampo);

          if (
            value &&
            !field.validacoes.some(
              (validacao) => validacao.idCondicaoParaValidacao === 2,
            )
          ) {
            formRef.current?.setFieldValue(
              nomeCampo,
              handleFormatText(value, field.formatarTexto || ''),
            );
          }
        });
    });
  }, [form, handleFormatText]);

  useEffect(() => {
    if (form) setFormValidation(setValidationErrors(form, inputVisible));
  }, [form, inputVisible]);

  useEffect(() => {
    if (crypto) {
      setCrypto(crypto);
      getData(crypto);
    } else if (idCampaign && idLead) {
      getInternalEditData(idCampaign, idLead);
    } else {
      getInternalData(idCampaign);
    }
  }, [
    getData,
    getInternalEditData,
    getInternalData,
    setCrypto,
    crypto,
    idCampaign,
    idLead,
  ]);

  useEffect(() => {
    if (idEstablishment)
      setPredefined(
        (state) => (state = {...state, establishment: idEstablishment}),
      );
    if (idCourse)
      setPredefined((state) => (state = {...state, course: idCourse}));
    if (idCycle) setPredefined((state) => (state = {...state, cycle: idCycle}));
    if (idShift) setPredefined((state) => (state = {...state, shift: idShift}));
  }, [idCourse, idCycle, idEstablishment, idShift]);

  useEffect(() => {
    if (predefined.establishment !== idEstablishment) {
      setPredefined(
        (state) =>
          (state = {
            ...state,
            course: '',
            cycle: '',
            shift: '',
          }),
      );
    }
  }, [idEstablishment, predefined.establishment]);

  useEffect(() => {
    if (predefined.course !== idCourse) {
      setPredefined(
        (state) =>
          (state = {
            ...state,
            cycle: '',
            shift: '',
          }),
      );
    }
  }, [idCourse, predefined.course]);

  useEffect(() => {
    if (idOffer) {
      handleGetSchedules(idOffer);
    }
  }, [idOffer, handleGetSchedules]);

  useEffect(() => {
    form.map((i) =>
      i.campos.map((field) => {
        if (field.idTipoDeCampo === 4) {
          if (field.resposta?.valor) {
            setInputVisible(
              (state) =>
                (state = {
                  ...state,
                  [field.idCampo]: {
                    visible: false,
                    value: field.resposta?.valor,
                  },
                }),
            );
          } else {
            setInputVisible(
              (state) =>
                (state = {
                  ...state,
                  [field.idCampo]: {
                    visible: true,
                    value: '',
                  },
                }),
            );
          }
        }
      }),
    );
  }, [form]);

  if (loading) {
    return <SplashScreen />;
  }

  if (message && message.length > 0) {
    return <SplashScreen message={message} />;
  }

  if (isError) {
    return <SplashScreen error />;
  }

  return (
    <Box maxWidth="100%">
      {isLoading && (
        <div className={classes.overlay}>
          <CircularProgress />
        </div>
      )}

      {!isInternal && <Header personalization={personalization} />}

      <Box marginTop={2} padding={3}>
        <Form
          ref={formRef}
          onSubmit={handleOnSubmit}
          initialData={initialData}
          onChange={handleChange}>
          <Grid container spacing={3}>
            {!isInternal && personalization.instrucaoParaCabecalhoFormulario && (
              <Box
                width="100%"
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html:
                    personalization.instrucaoParaCabecalhoFormulario.replaceAll(
                      'class',
                      'className',
                    ),
                }}
              />
            )}

            {form?.map((item, indexSec) => (
              <Fragment key={item.idSecao}>
                {item.campos.length > 0 &&
                  item.apresentarNoFormulario &&
                  item.campos.filter(
                    (i) => i.ativo && (i.disponivelParaLead || isInternal),
                  ).length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        <strong>{item.nomeSecao}</strong>
                      </Typography>
                    </Grid>
                  )}
                {item.campos?.map((field) => (
                  <Fragment key={field.idCampo}>
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 1 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          <AvailableSchedule
                            label={
                              field.obrigatorio
                                ? `${field.nomeCampo} *`
                                : field.nomeCampo
                            }
                            error={field.obrigatorio && submitted}
                            helperText={
                              field.obrigatorio &&
                              submitted &&
                              'Campo requerido.'
                            }
                            disabled={!permissionEdit}
                            name={stringCapitalize(
                              field.nomeCampo,
                              field.idCampo,
                            )}
                            availabilities={availabilities}
                          />
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 2 && (
                        <Grid key={field.idCampo} item xs={12} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}
                                disabled={!permissionEdit}
                                color="primary"
                              />
                            }
                            label={
                              <>
                                {field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo}{' '}
                                {field.textoDeAjuda && (
                                  <InfoTooltip title={field.textoDeAjuda} />
                                )}
                              </>
                            }
                          />
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 3 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          <TextField
                            id={String(field.idCampo)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={!permissionEdit}
                            label={
                              field.obrigatorio
                                ? `${field.nomeCampo} *`
                                : field.nomeCampo
                            }
                            name={stringCapitalize(
                              field.nomeCampo,
                              field.idCampo,
                            )}
                            type="date"
                            helper={
                              field.textoDeAjuda
                                ? field.textoDeAjuda
                                : undefined
                            }
                          />
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 4 && (
                        <Grid
                          item
                          xs={12}
                          key={field.idCampo}
                          alignItems="center"
                          sm={handleMaxColumns(item.maximoColunas)}>
                          {isEditing && !inputVisible[field.idCampo].visible ? (
                            <Grid container alignItems="center" spacing={3}>
                              <Grid item xs={6} md={9}>
                                <TextField
                                  disabled
                                  InputLabelProps={{shrink: true}}
                                  label={
                                    field.obrigatorio
                                      ? `${field.nomeCampo} *`
                                      : field.nomeCampo
                                  }
                                  name={`${stringCapitalize(
                                    field.nomeCampo,
                                    field.idCampo,
                                  )}-edit`}
                                  value={field.resposta?.nomeArquivo}
                                  InputProps={{
                                    inputProps: {
                                      accept: 'image/*,application/pdf',
                                    },
                                    endAdornment: (
                                      <label htmlFor={String(field.idCampo)}>
                                        <IconButton
                                          disabled
                                          color="primary"
                                          component="span"
                                          aria-label="upload">
                                          <CloudUpload />
                                        </IconButton>
                                      </label>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid xs={2} md={1}>
                                <IconButton
                                  color="primary"
                                  component="span"
                                  disabled={!permissionEdit}
                                  onClick={() =>
                                    setInputVisible(
                                      (state) =>
                                        (state = {
                                          ...state,
                                          [field.idCampo]: {
                                            visible: true,
                                            value: '',
                                          },
                                        }),
                                    )
                                  }>
                                  <Delete />
                                </IconButton>
                              </Grid>
                              <Grid xs={2} md={1}>
                                <IconButton
                                  color="primary"
                                  component="span"
                                  disabled={!permissionEdit}
                                  onClick={() =>
                                    setInputVisible(
                                      (state) =>
                                        (state = {
                                          ...state,
                                          [field.idCampo]: {
                                            visible: true,
                                            value: '',
                                          },
                                        }),
                                    )
                                  }>
                                  <Create />
                                </IconButton>
                              </Grid>
                              <Grid xs={2} md={1}>
                                <IconButton
                                  color="primary"
                                  component="span"
                                  disabled={!permissionEdit}
                                  onClick={() =>
                                    field.resposta?.valor &&
                                    handleGetFile(field.resposta.valor)
                                  }>
                                  <Visibility />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ) : (
                            <>
                              <TextField
                                type="file"
                                disabled={!permissionEdit}
                                id={String(field.idCampo)}
                                InputLabelProps={{shrink: true}}
                                label={
                                  field.obrigatorio
                                    ? `${field.nomeCampo} *`
                                    : field.nomeCampo
                                }
                                name={stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}
                                helper={
                                  field.textoDeAjuda
                                    ? field.textoDeAjuda
                                    : undefined
                                }
                                InputProps={{
                                  inputProps: {
                                    accept: 'image/*,application/pdf',
                                  },
                                  endAdornment: (
                                    <label htmlFor={String(field.idCampo)}>
                                      <IconButton
                                        color="primary"
                                        component="span"
                                        aria-label="upload">
                                        <CloudUpload />
                                      </IconButton>
                                    </label>
                                  ),
                                }}
                              />
                              <TextField
                                style={{display: 'none'}}
                                disabled
                                id={`${String(field.idCampo)}-file`}
                                name={`${stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}-file`}
                              />
                            </>
                          )}
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 5 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          {idEstablishment && field.idCampoPredefinido === 2 ? (
                            <TextField
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}
                              label={
                                field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              disabled={!permissionEdit}
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              value={predefined.establishment}
                              onChange={({target}) =>
                                setPredefined({
                                  establishment: target.value,
                                  course: '',
                                  cycle: '',
                                  shift: '',
                                  additional1: '',
                                  additional2: '',
                                })
                              }
                              select>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : idCourse && field.idCampoPredefinido === 3 ? (
                            <TextField
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}
                              label={
                                field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              disabled={!permissionEdit}
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              value={predefined.course}
                              onChange={({target}) =>
                                setPredefined(
                                  (state) =>
                                    (state = {
                                      ...state,
                                      course: target.value,
                                      cycle: '',
                                      shift: '',
                                    }),
                                )
                              }
                              select>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : idShift && field.idCampoPredefinido === 3 ? (
                            <TextField
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}
                              disabled={
                                handleOptPredefined(field)?.length === 0 ||
                                !permissionEdit
                              }
                              label={
                                field.obrigatorio &&
                                handleOptPredefined(field)?.length > 0
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              onChange={({target}) =>
                                setPredefined(
                                  (state) =>
                                    (state = {
                                      ...state,
                                      shift: target.value,
                                    }),
                                )
                              }
                              select>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <TextField
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}
                              disabled={
                                handleOptPredefined(field)?.length === 0 ||
                                !permissionEdit
                              }
                              label={
                                field.obrigatorio &&
                                handleOptPredefined(field)?.length > 0
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              value={handleValuePredefined(
                                field.idCampoPredefinido,
                                field.idCampo,
                                field.nomeCampo,
                              )}
                              onChange={({target}) => {
                                handleSetPredefined(
                                  target,
                                  field.idCampoPredefinido,
                                  field.nomeCampo,
                                  field.idCampo,
                                );
                              }}
                              select>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 6 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          <MultiSelect
                            id={String(field.idCampo)}
                            label={
                              field.obrigatorio
                                ? `${field.nomeCampo} *`
                                : field.nomeCampo
                            }
                            disabled={!permissionEdit}
                            helper={
                              field.textoDeAjuda
                                ? field.textoDeAjuda
                                : undefined
                            }
                            name={stringCapitalize(
                              field.nomeCampo,
                              field.idCampo,
                            )}>
                            {handleOptPredefined(field)?.map((opt) => (
                              <MenuItem key={opt.id} value={opt.id}>
                                {opt.nome}
                              </MenuItem>
                            ))}
                          </MultiSelect>
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 7 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          {!isEditing &&
                          field.mascara &&
                          field.idTipoMascara ? (
                            <>
                              <InputMask
                                defaultValue={field.resposta?.valor}
                                mask={field.mascara}
                                id={String(field.idCampo)}
                                disabled={!permissionEdit}
                                onBlur={({target}) =>
                                  field.idCampoPredefinido === 8 &&
                                  handleGetAddress(target.value, indexSec)
                                }>
                                {() => (
                                  <TextField
                                    type="number"
                                    inputProps={{
                                      min: '1.0',
                                      step: '0.1',
                                    }}
                                    id={String(field.idCampo)}
                                    defaultValue={field.resposta?.valor}
                                    label={
                                      field.obrigatorio
                                        ? `${field.nomeCampo} *`
                                        : field.nomeCampo
                                    }
                                    disabled={!permissionEdit}
                                    helper={
                                      field.textoDeAjuda
                                        ? field.textoDeAjuda
                                        : undefined
                                    }
                                    name={stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}>
                                    {handleOptPredefined(field)?.map((opt) => (
                                      <MenuItem key={opt.id} value={opt.id}>
                                        {opt.nome}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              </InputMask>
                            </>
                          ) : (
                            <TextField
                              type="number"
                              inputProps={{
                                min: '1',
                                step: '1',
                                max: '99',
                              }}
                              id={String(field.idCampo)}
                              defaultValue={field.resposta?.valor}
                              label={
                                field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              disabled={!permissionEdit}
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 8 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          {!isEditing &&
                          field.mascara &&
                          field.idTipoMascara ? (
                            <>
                              <InputMask
                                defaultValue={field.resposta?.valor}
                                mask={field.mascara}
                                id={String(field.idCampo)}
                                disabled={!permissionEdit}
                                onBlur={({target}) =>
                                  field.idCampoPredefinido === 8 &&
                                  handleGetAddress(target.value, indexSec)
                                }>
                                {() => (
                                  <TextField
                                    type="number"
                                    inputProps={{
                                      min: '1.0',
                                      step: '0.1',
                                      max: '3.0',
                                    }}
                                    id={String(field.idCampo)}
                                    defaultValue={field.resposta?.valor}
                                    label={
                                      field.obrigatorio
                                        ? `${field.nomeCampo} *`
                                        : field.nomeCampo
                                    }
                                    disabled={!permissionEdit}
                                    helper={
                                      field.textoDeAjuda
                                        ? field.textoDeAjuda
                                        : undefined
                                    }
                                    name={stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}>
                                    {handleOptPredefined(field)?.map((opt) => (
                                      <MenuItem key={opt.id} value={opt.id}>
                                        {opt.nome}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              </InputMask>
                            </>
                          ) : (
                            <TextField
                              type="number"
                              inputProps={{
                                min: '1.0',
                                step: '0.1',
                                max: '3.0',
                              }}
                              id={String(field.idCampo)}
                              defaultValue={field.resposta?.valor}
                              label={
                                field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              disabled={!permissionEdit}
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}>
                              {handleOptPredefined(field)?.map((opt) => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.nome}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 9 && (
                        <Grid
                          key={field.idCampo}
                          item
                          xs={12}
                          sm={handleMaxColumns(item.maximoColunas)}>
                          {!isEditing &&
                          field.mascara &&
                          field.idTipoMascara ? (
                            <>
                              <InputMask
                                defaultValue={field.resposta?.valor}
                                mask={field.mascara}
                                id={String(field.idCampo)}
                                disabled={!permissionEdit}
                                onBlur={({target}) =>
                                  field.idCampoPredefinido === 8 &&
                                  handleGetAddress(target.value, indexSec)
                                }>
                                {() => (
                                  <TextField
                                    label={
                                      field.obrigatorio
                                        ? `${field.nomeCampo} *`
                                        : field.nomeCampo
                                    }
                                    helper={
                                      field.textoDeAjuda
                                        ? field.textoDeAjuda
                                        : field.idCampoPredefinido === 8 &&
                                          isInvalideCep &&
                                          !cepActive[indexSec]
                                        ? 'CEP inválido'
                                        : undefined
                                    }
                                    name={stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}
                                    disabled={!permissionEdit}
                                  />
                                )}
                              </InputMask>
                              {field.idCampoPredefinido === 8 &&
                                useZipCode.usaIntegracaoCep && (
                                  <Link
                                    target="_blank"
                                    className={classes.zipCode}
                                    href={useZipCode.linkPesquisaCep}>
                                    Não sei informar meu CEP
                                  </Link>
                                )}
                            </>
                          ) : Object.keys(cepActive).length &&
                            (field.idCampoPredefinido === 9 ||
                              field.idCampoPredefinido === 12 ||
                              field.idCampoPredefinido === 13 ||
                              field.idCampoPredefinido === 14) ? (
                            <TextField
                              id={String(field.idCampo)}
                              InputLabelProps={{shrink: true}}
                              disabled={cepActive[indexSec] || !permissionEdit}
                              label={
                                field.obrigatorio
                                  ? `${field.nomeCampo} *`
                                  : field.nomeCampo
                              }
                              value={handleSetAddress(
                                field.idCampoPredefinido,
                                indexSec,
                              )}
                              helper={
                                field.textoDeAjuda
                                  ? field.textoDeAjuda
                                  : undefined
                              }
                              name={stringCapitalize(
                                field.nomeCampo,
                                field.idCampo,
                              )}
                            />
                          ) : isEditing && field.idTipoMascara ? (
                            <>
                              <InputMask
                                mask={field.mascara}
                                id={String(field.idCampo)}
                                disabled={!permissionEdit}
                                defaultValue={field.resposta?.valor}
                                onBlur={({target}) =>
                                  field.idCampoPredefinido === 8 &&
                                  handleGetAddress(target.value, indexSec)
                                }>
                                {() => (
                                  <TextField
                                    disabled={!permissionEdit}
                                    label={
                                      field.obrigatorio
                                        ? `${field.nomeCampo} *`
                                        : field.nomeCampo
                                    }
                                    helper={
                                      field.textoDeAjuda
                                        ? field.textoDeAjuda
                                        : field.idCampoPredefinido === 8 &&
                                          isInvalideCep &&
                                          !cepActive[indexSec]
                                        ? 'CEP inválido'
                                        : undefined
                                    }
                                    name={stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}
                                  />
                                )}
                              </InputMask>
                              {field.idCampoPredefinido === 8 &&
                                useZipCode.usaIntegracaoCep && (
                                  <Link
                                    target="_blank"
                                    className={classes.zipCode}
                                    href={useZipCode.linkPesquisaCep}>
                                    Não sei informar meu CEP
                                  </Link>
                                )}
                            </>
                          ) : (
                            <>
                              <TextField
                                id={String(field.idCampo)}
                                disabled={!permissionEdit}
                                label={
                                  field.obrigatorio
                                    ? `${field.nomeCampo} *`
                                    : field.nomeCampo
                                }
                                onBlur={({target}) =>
                                  field.idCampoPredefinido === 8 &&
                                  handleGetAddress(target.value, indexSec)
                                }
                                helper={
                                  field.textoDeAjuda
                                    ? field.textoDeAjuda
                                    : field.idCampoPredefinido === 8 &&
                                      isInvalideCep &&
                                      !cepActive[indexSec]
                                    ? 'CEP inválido'
                                    : undefined
                                }
                                name={stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}
                                error={
                                  (field.idCampoPredefinido === 8 &&
                                    isInvalideCep) ||
                                  (field.obrigatorio && submitted)
                                }
                              />
                              {field.idCampoPredefinido === 8 &&
                                useZipCode.usaIntegracaoCep && (
                                  <Link
                                    target="_blank"
                                    className={classes.zipCode}
                                    href={useZipCode.linkPesquisaCep}>
                                    Não sei informar meu CEP
                                  </Link>
                                )}
                            </>
                          )}
                        </Grid>
                      )}
                    {field.ativo &&
                      (field.disponivelParaLead || isInternal) &&
                      field.idTipoDeCampo === 10 && (
                        <Grid key={field.idCampo} item xs={12} sm={12}>
                          <TextField
                            id={String(field.idCampo)}
                            label={
                              field.obrigatorio
                                ? `${field.nomeCampo} *`
                                : field.nomeCampo
                            }
                            helper={
                              field.textoDeAjuda
                                ? field.textoDeAjuda
                                : undefined
                            }
                            name={stringCapitalize(
                              field.nomeCampo,
                              field.idCampo,
                            )}
                            disabled={!permissionEdit}
                            multiline
                            rows={3}
                          />
                        </Grid>
                      )}
                  </Fragment>
                ))}
              </Fragment>
            ))}

            {!isInternal ? (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop={3}
                  marginBottom={3}>
                  <ReCAPTCHA
                    sitekey="6Lc37DEbAAAAABy91EIWTie45JA9ddJ3SZ0Lk1ZB"
                    onChange={handleChangeRecaptcha}
                  />
                </Box>
              </Grid>
            ) : (
              <Box padding={3} />
            )}

            {!isInternal && personalization.instrucaoParaRodapeFormulario && (
              <Box
                width="100%"
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html:
                    personalization.instrucaoParaRodapeFormulario.replaceAll(
                      'class',
                      'className',
                    ),
                }}
              />
            )}

            {permissionEdit && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit">
                  Confirmar
                </Button>
              </Grid>
            )}
          </Grid>
        </Form>
      </Box>
    </Box>
  );
};
