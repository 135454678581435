import React, {useEffect, useState, useRef} from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import {useField} from '@unform/core';

export const Checkbox: React.FC<CheckboxProps & {name: string}> = ({
  name,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {fieldName, defaultValue, registerField, error} = useField(name);

  const [isChecked, setIsChecked] = useState<boolean>(defaultValue);

  useEffect(() => {
    registerField({
      ref: inputRef.current,
      name: fieldName,
      getValue: () => isChecked,
      setValue: (_, value) => setIsChecked(value),
    });
  }, [fieldName, isChecked, registerField]);

  return (
    <FormControl>
      <MuiCheckbox
        inputRef={inputRef}
        {...rest}
        checked={isChecked}
        onChange={(e) => setIsChecked(e.target.checked)}
      />
      {error ? (
        <FormHelperText
          style={{
            width: '120px',
            marginTop: '40px',
            marginLeft: '3px',
            position: 'absolute',
            zIndex: 1,
          }}
          error={!!error}>
          {error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};
