import React, {createContext, useCallback, useEffect, useState} from 'react';
import {createMuiTheme, CssBaseline, ThemeProvider} from '@material-ui/core';
import {ptBR} from 'date-fns/locale';

import {
  IForm,
  IOffer,
  IOptions,
  IZipCode,
  IPostData,
  FormService,
  IPersonalization,
} from 'shared/services/api/FormService';

export interface IContext {
  isInternal: boolean;
  getData(crypto: string): Promise<void>;
  getInternalData(idCampaign: string): Promise<void>;
  getInternalEditData(idCampaign: string, idLead: string): Promise<void>;
  postData(
    token: string,
    payload: Omit<IPostData, 'idCampanha'>,
  ): Promise<void>;
  postInternalData(payload: Omit<IPostData, 'idCampanha'>): Promise<void>;
  putData(id: string, payload: Omit<IPostData, 'idCampanha'>): Promise<void>;
  setCrypto(value: string): void;
  crypto: string;
  idEstablishment: string;
  idCourse: string;
  idCycle: string;
  idShift: string;
  form: IForm[];
  offers: IOffer[];
  shift: IOptions[];
  course: IOptions[];
  cycle: IOptions[];
  establishment: IOptions[];
  personalization: IPersonalization;
  loading: boolean;
  isError: boolean;
  isEditing: boolean;
  permissionEdit: boolean;
  message: string;
  useZipCode: IZipCode;
}

export const ResponseContext = createContext<IContext>({} as IContext);

export const ResponseProvider: React.FC = ({children}) => {
  const [isInternal, setIsInternal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [permissionEdit, setPermissionEdit] = useState(true);
  const [message, setMessage] = useState('');

  const [crypto, setCrypto] = useState('');
  const [idCycle, setIdCycle] = useState('');
  const [idShift, setIdShift] = useState('');
  const [idCourse, setIdCourse] = useState('');
  const [idCampaign, setIdCampaign] = useState('');
  const [cycle, setCycle] = useState<IOptions[]>([]);
  const [shift, setShift] = useState<IOptions[]>([]);
  const [course, setCourse] = useState<IOptions[]>([]);
  const [idEstablishment, setIdEstablishment] = useState('');
  const [establishment, setEstablishment] = useState<IOptions[]>([]);
  const [useZipCode, setUseZipCode] = useState<IZipCode>({
    linkPesquisaCep: '',
    usaIntegracaoCep: false,
  });

  const [form, setForm] = useState<IForm[]>([]);
  const [offers, setOffers] = useState<IOffer[]>([]);
  const [personalization, setPersonalization] = useState<IPersonalization>(
    {} as IPersonalization,
  );
  const [theme, setTheme] = useState(createMuiTheme({}, ptBR));

  useEffect(() => {
    setIsInternal(window.location.pathname.includes('formulario-interno'));
  }, []);

  const handleGetResponse = useCallback(async (crypto: string) => {
    setLoading(true);

    try {
      const response = await FormService.getData(crypto);

      if (response && typeof response?.data !== 'string') {
        setPersonalization(response?.data?.personalizacao);
        setForm(response?.data.secoes);
        setOffers(response?.data.ofertas);
        setIdCampaign(response?.data.idCampanha);
        setIdCourse(response?.data.idCurso);
        setIdEstablishment(response?.data.idEstabelecimento);
        if (response?.data.idCurso) setIdCourse(response?.data.idCurso);
        if (response?.data.idEstabelecimentoPreSelecionado) {
          setIdEstablishment(response?.data.idEstabelecimentoPreSelecionado);
        } else if (response?.data.idEstabelecimento) {
          setIdEstablishment(response?.data.idEstabelecimento);
        }
        setCourse(response?.data.cursos);
        setCycle(response?.data.ciclos);
        setShift(response?.data.turnos);
        setEstablishment(response?.data.estabelecimentos);
        setOffers(response?.data.ofertas);
        setUseZipCode(response?.data.cep);
      } else {
        setMessage(response?.data as string);
      }
    } catch (error) {
      setLoading(false);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetInternalResponse = useCallback(async (idCampaign: string) => {
    setLoading(true);
    try {
      const response = await FormService.getInternalData(idCampaign);

      if (response) {
        setForm(response.secoes);
        setOffers(response.ofertas);
        setIdCampaign(idCampaign);
        if (response.idCurso) setIdCourse(response?.idCurso);
        if (response.idEstabelecimentoPreSelecionado) {
          setIdEstablishment(response.idEstabelecimentoPreSelecionado);
        } else if (response.idEstabelecimento) {
          setIdEstablishment(response.idEstabelecimento);
        }
        setCourse(response?.cursos);
        setCycle(response?.ciclos);
        setShift(response?.turnos);
        setEstablishment(response?.estabelecimentos);
        setOffers(response?.ofertas);
        setUseZipCode(response?.cep);
      }
    } catch (error) {
      setLoading(false);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetInternalEditResponse = useCallback(
    async (idCampaign: string, idLead: string) => {
      setLoading(true);
      try {
        const response = await FormService.getInternalEditData(
          idCampaign,
          idLead,
        );

        if (response) {
          setForm(response?.secoes);
          setIdCampaign(idCampaign);
          setIdEstablishment(response?.idEstabelecimento);
          setIdCourse(response?.idCurso);
          setIdCycle(response?.idCiclo);
          setIdShift(response?.idTurno);
          setCourse(response?.cursos);
          setCycle(response?.ciclos);
          setShift(response?.turnos);
          setEstablishment(response?.estabelecimentos);
          setOffers(response?.ofertas);
          setIsEditing(true);
          setPermissionEdit(response?.permiteEditar);
        }
      } catch (error) {
        setLoading(false);
        setIsError(true);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const handlePostResponse = useCallback(
    async (token: string, data: Omit<IPostData, 'idCampanha'>) => {
      setLoading(true);

      try {
        const payload = {
          idCampanha: idCampaign,
          idOferta: data.idOferta,
          respostas: data.respostas,
        };

        await FormService.postData(token, payload);
      } catch (error) {
        throw new Error(`Erro ao salvar o formulário: ${error}`);
      } finally {
        setLoading(false);
      }
    },
    [idCampaign],
  );

  const handlePostInternalResponse = useCallback(
    async (data: Omit<IPostData, 'idCampanha'>) => {
      setLoading(true);

      try {
        const payload = {
          idCampanha: idCampaign,
          idOferta: data.idOferta,
          respostas: data.respostas,
        };

        await FormService.postInternalData(payload);
      } catch (error) {
        throw new Error(`Erro ao salvar o formulário: ${error}`);
      } finally {
        setLoading(false);
      }
    },
    [idCampaign],
  );

  const handlePutResponse = useCallback(
    async (id: string, data: Omit<IPostData, 'idCampanha'>) => {
      setLoading(true);

      try {
        const payload = {
          idCampanha: idCampaign,
          idOferta: data.idOferta,
          respostas: data.respostas,
        };

        await FormService.putData(id, payload);
      } catch (error) {
        throw new Error(`Erro ao editar o formulário: ${error}`);
      } finally {
        setLoading(false);
      }
    },
    [idCampaign],
  );

  useEffect(() => {
    if (personalization) {
      setTheme(
        (state) =>
          (state = {
            ...state,
            palette: {
              ...state.palette,
              primary: {
                ...state.palette.primary,
                main: isInternal
                  ? '#018781'
                  : personalization?.corBotaoDeConclusaoFormulario || '#018781',
                dark: isInternal
                  ? '#005E5A'
                  : `${personalization?.corBotaoDeConclusaoFormulario}DD` ||
                    '#005E5A',
                light: isInternal
                  ? '#339F9A'
                  : `${personalization?.corBotaoDeConclusaoFormulario}88` ||
                    '#339F9A',
              },
            },
          }),
      );
    }
  }, [personalization, isInternal]);

  return (
    <ResponseContext.Provider
      value={{
        isInternal,
        getData: handleGetResponse,
        getInternalData: handleGetInternalResponse,
        getInternalEditData: handleGetInternalEditResponse,
        postInternalData: handlePostInternalResponse,
        postData: handlePostResponse,
        putData: handlePutResponse,
        setCrypto,
        crypto,
        idEstablishment,
        idCourse,
        idCycle,
        idShift,
        form,
        offers,
        course,
        cycle,
        establishment,
        shift,
        personalization,
        loading,
        isError,
        isEditing,
        permissionEdit,
        message,
        useZipCode,
      }}>
      <ThemeProvider theme={theme}>
        {children}
        <CssBaseline />
      </ThemeProvider>
    </ResponseContext.Provider>
  );
};
