import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  availableDay: {
    position: 'absolute',
    bottom: '-3px',
    left: '50%',
    transform: 'translateX(-50%)',
  },

  icon: {
    fontSize: '0.50rem',
  },

  dialogContent: {
    overflow: 'hidden',
  },

  radioGroup: {
    margin: '10px 0',
  },

  buttonBox: {
    display: 'flex',
    gridGap: '10px',
    justifyContent: 'flex-end',
    margin: '10px 0',
  },

  hoursListContent: {
    marginTop: '10px',
  },
}));
