import React from 'react';

import {AppRoutes} from 'routes';
import {AppProvider} from 'shared/contexts';

export const App: React.FC = () => {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
};
