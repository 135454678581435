import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useField} from '@unform/core';

import {
  Box,
  Grow,
  Radio,
  Button,
  Dialog,
  Divider,
  FormLabel,
  TextField,
  IconButton,
  RadioGroup,
  Typography,
  FormControl,
  DialogContent,
  InputAdornment,
  FormControlLabel,
  TextFieldProps,
} from '@material-ui/core';
import RoundedIcon from '@material-ui/icons/Brightness1Rounded';
import EventIcon from '@material-ui/icons/Event';

import {Calendar, Day} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';

import {format} from 'date-fns';

import {IScheduleAvailability} from 'shared/services/api/ScheduleAvailabilityService';
import {useStyles} from './style';

interface IAvailableScheduleProps {
  label: string;
  availabilities: IScheduleAvailability[];
}

export const AvailableSchedule: React.FC<
  IAvailableScheduleProps &
    TextFieldProps & {
      name: string;
    }
> = ({name, error, helperText, label, availabilities}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const [selectedOption, setSelectedOption] = useState<IScheduleAvailability>();
  const [availableOptions, setAvailableOptions] = useState<
    IScheduleAvailability[]
  >([]);

  const [selectedHour, setSelectedHour] = useState<string>();

  const inputRef = useRef<HTMLInputElement>(null);
  const {fieldName, defaultValue, registerField} = useField(name);

  useEffect(() => {
    registerField({
      ref: inputRef.current,
      name: fieldName,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const isEqualsDate = useCallback(
    (date: IScheduleAvailability, day: MaterialUiPickersDate) => {
      const dateAvailable = new Date(date.iniciaEm);
      const currentDayVerification = day ? new Date(day) : false;

      if (!currentDayVerification) return false;

      return (
        dateAvailable.getDate() === currentDayVerification.getDate() &&
        dateAvailable.getMonth() === currentDayVerification.getMonth() &&
        dateAvailable.getFullYear() === currentDayVerification.getFullYear()
      );
    },
    [],
  );

  const renderDay = useCallback(
    (
      day: MaterialUiPickersDate,
      selectedDate: MaterialUiPickersDate,
      isInCurrentMonth: boolean,
      dayComponent: JSX.Element,
    ) => {
      const matchFound = availabilities.findIndex((date) =>
        isEqualsDate(date, day),
      );

      const isAvailable =
        isInCurrentMonth &&
        matchFound > -1 &&
        matchFound < availabilities.length
          ? true
          : false;

      const isSelectedDay =
        selectedDate && day && selectedDate.getDate() === day.getDate();

      return (
        <Day>
          {dayComponent}
          {isAvailable && (
            <div className={classes.availableDay}>
              <RoundedIcon
                color={isSelectedDay ? 'secondary' : 'primary'}
                className={classes.icon}
              />
            </div>
          )}
        </Day>
      );
    },
    [availabilities, classes.availableDay, classes.icon, isEqualsDate],
  );

  const handleSelectHour = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedHour((event.target as HTMLInputElement).value);
    },
    [],
  );

  const handleConfirmHour = useCallback(() => {
    if (!selectedHour) return;

    const indexFound = availabilities.findIndex(
      (item) => item.iniciaEm === selectedHour,
    );

    if (indexFound !== -1) {
      setSelectedOption(availabilities[indexFound]);
    }

    setIsOpen(false);
  }, [availabilities, selectedHour]);

  useEffect(() => {
    const matchesFiltered = availabilities.filter((date) =>
      isEqualsDate(date, selectedDate),
    );

    setAvailableOptions(matchesFiltered);
  }, [availabilities, isEqualsDate, selectedDate]);

  useEffect(() => {
    if (defaultValue) {
      const aux = availabilities.filter(
        (item) => item.idDisponibilidadeDeAgenda === defaultValue,
      )[0];

      setSelectedOption(aux);
    }
  }, [defaultValue, availabilities]);

  return (
    <>
      <TextField
        inputRef={inputRef}
        fullWidth
        name={name}
        label={label}
        variant="outlined"
        value={
          selectedOption
            ? `${format(
                new Date(selectedOption.iniciaEm),
                'dd/MM/yyyy HH:mm',
              )} às ${format(new Date(selectedOption.terminaEm), 'HH:mm')}`
            : ''
        }
        onChange={() => setIsOpen(true)}
        error={error}
        defaultValue={defaultValue}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setIsOpen(true)}>
                <EventIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
        <DialogContent className={classes.dialogContent}>
          <Calendar
            disablePast
            date={selectedDate}
            renderDay={renderDay}
            onChange={setSelectedDate}
          />
        </DialogContent>

        <Divider />

        <DialogContent className={classes.hoursListContent}>
          <Typography variant="body1">
            {selectedDate
              ? `Dia ${selectedDate.getDate()}`
              : 'Nenhuma data selecionada'}
          </Typography>

          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography variant="caption">
                Escolha um horário disponível
              </Typography>
            </FormLabel>

            <RadioGroup
              aria-label="horários disponíveis"
              className={classes.radioGroup}
              value={selectedHour}
              onChange={handleSelectHour}>
              {availableOptions.length
                ? availableOptions.map((option, index) => {
                    const timeout = 1000 * index;

                    return (
                      <Grow
                        in
                        key={index}
                        style={{transformOrigin: '0 0 0'}}
                        {...{timeout: timeout}}>
                        <FormControlLabel
                          value={option.iniciaEm}
                          control={<Radio />}
                          label={`${format(
                            new Date(option.iniciaEm),
                            'HH:mm',
                          )} - ${format(new Date(option.terminaEm), 'HH:mm')}`}
                        />
                      </Grow>
                    );
                  })
                : 'Não há dias disponíveis...'}
            </RadioGroup>
          </FormControl>

          <Box className={classes.buttonBox}>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => setIsOpen(false)}>
              Cancelar
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleConfirmHour}>
              Confirmar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
