import {Api} from 'shared/services/axiosConfig';

export interface IScheduleAvailability {
  idDisponibilidadeDeAgendaAtribuida: string | null;
  idDisponibilidadeDeAgenda: string;
  idEntrevistador: string;
  nomeEntrevistador: string;
  iniciaEm: string;
  terminaEm: string;
  observacao: string;
}

const getAvailabilities = async (
  idOferta: string,
): Promise<IScheduleAvailability[] | undefined> => {
  try {
    const response = await Api().get<IScheduleAvailability[]>(
      `/disponibilidadedeagenda/disponibilidadeparalead?idOferta=${idOferta}`,
    );

    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const AvailabilitiesService = {
  getAvailabilities,
};
