import {makeStyles, Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    logo: {
      width: '80px',
      position: 'relative',
      transition: 'transform .2s',
      '&:hover': {transform: 'scale(1.25)'},
    },
    bar: {
      width: theme.spacing(35),
      marginTop: theme.spacing(6),
      colorPrimary: theme.palette.primary.main,
    },
    text: {
      marginTop: theme.spacing(3),
      color: theme.palette.text.primary,
    },
  };
});
