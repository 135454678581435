import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {FormPage} from 'pages/Form';
import {ConfirmationPage} from 'pages/Confirmation';

export const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/formulario/:crypto" element={<FormPage />} />
        <Route path="/formulario-interno/:idCampaign" element={<FormPage />} />
        <Route
          path="/formulario-interno-com-respostas/:idCampaign/:idLead"
          element={<FormPage />}
        />
        <Route path="/confirmacao" element={<ConfirmationPage />} />
      </Routes>
    </BrowserRouter>
  );
};
